<template>
  <div>
    <div v-if="addActiveDisable">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>全场活动</el-breadcrumb-item>
          <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="padding: 5px">
          <i class="el-icon-close icon" @click="closeClick"></i>
        </div>
      </div>
      <div class="tableInput">
        <span>日期范围：</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date1"
          style="width: 12%"
        ></el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date2"
          style="width: 12%"
        ></el-date-picker>
        <el-select v-model="value" @change="optionClick(value)">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="QueryContent"
          placeholder="请输入内容"
          style="width: 15%"
        ></el-input>
        <el-button type="success" @click="searchClick"
          ><i class="el-icon-search"></i>查询</el-button
        >
      </div>
      <div class="tableBtn">
        <el-button type="primary" @click="addActiveDisable = !addActiveDisable"
          ><i class="el-icon-plus"></i> 发布活动</el-button
        >
        <el-button type="danger" @click="defaultList"
          ><i class="el-icon-delete"></i> 批量删除</el-button
        >
      </div>
      <div class="table">
        <!-- 表格 -->
        <div style="padding: 10px 0">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; border: 1px solid #e5e5e5"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column
              prop="priority"
              sortable
              label="优先级"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="activity_title" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="activity_type" label="类型" align="center">
            </el-table-column>
            <el-table-column prop="status" sortable label="状态" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <i
                  class="el-icon-edit poiner"
                  @click="handleEdit(scope.row)"
                ></i>
                <span style="margin: 0 10px"></span>
                <i
                  class="el-icon-delete poiner"
                  @click="handleDelete(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <div class="tableBottom">
            <span v-if="isResult" style=""
              >显示第&nbsp;1&nbsp;至&nbsp;{{
                tableFinshNum
              }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
            >
            <span v-if="!isResult" style=""
              >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
                tableFinshNum
              }}&nbsp;项</span
            >
            <div
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <el-button @click="onePage">首页</el-button>
              <el-button @click="backPage">上页</el-button>
              <el-pagination
                background
                layout=" pager,slot"
                :total="tableFinshNum"
                :current-page="page"
                @current-change="currentChange"
              ></el-pagination>
              <el-button @click="nextPage">下页</el-button>
              <el-button @click="endPage">末页</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!addActiveDisable">
      <transition name="fade">
        <add-active
          @close="closeAdd"
          :breadcrumb="breadcrumb"
          :redactVal="redactVal"
        ></add-active>
      </transition>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import { getTime } from "../../../../../until/getTime";
import { urlList } from "./child/data";
export default {
  props: ["breadcrumb"],
  components: {
    addActive: () => import("./child/overallAtive_add.vue"),
    // addActiveSeckill: () => import('./groupBooking_child/addActiveSeckill.vue')
  },
  data() {
    return {
      dateTime: { date1: "", date2: "" },
      QueryContent: "",
      value: "状态",
      options: [
        {
          value: "待发布",
          label: "待发布",
        },
        {
          value: "进行中",
          label: "进行中",
        },
        {
          value: "已关闭",
          label: "已关闭",
        },
      ],
      tableData: [],
      isResult: false,
      tableFinshNum: 0,
      addActiveDisable: true,
      breadcrumbs: 1,
      labels1: "",
      labels2: "",
      baserUrl: "",
      page: 1,
      length: 10,
      changeArr: [],
      redactVal: "",
    };
  },
  mounted() {
    urlList.forEach((item) => {
      if (item.title == this.$props.breadcrumb) {
        this.baserUrl = item.url;
      }
    });
    this.getTabList();
  },

  computed: {},
  methods: {
    closeAdd(data) {
      this.addActiveDisable = data;
      this.redactVal = "";
      this.getTabList();
    },
    optionClick(data) {
      console.log(data);
    },
    handleEdit(row) {
      this.redactVal = row;
      this.addActiveDisable = false;
    },
    handleDelete(row) {
      this.$confirm("确定删除活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(`${this.baserUrl}/del`, qs.stringify({ id: row.id }))
            .then(() => {
              this.getTabList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    defaultList() {
      if (this.changeArr.length > 0) {
        this.$confirm("确定删除活动, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const deleteArr = [];
            this.changeArr.forEach((item) => {
              deleteArr.push(item.id);
            });
            this.$http
              .post(
                `${this.baserUrl}/bacthDel`,
                qs.stringify({ idArr: deleteArr })
              )
              .then(() => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getTabList();
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleSelectionChange(val) {
      this.changeArr = val;
    },
    closeClick() {
      this.$emit("closeClick");
    },
    //获取表单数据
    getTabList() {
      this.$http
        .post(
          `${this.baserUrl}/lst`,
          qs.stringify({ currentPage: this.page, currentLength: this.length })
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    backPage() {
      this.page = --this.page;
      this.getTabList();
    },
    nextPage() {
      this.page = ++this.page;
      this.getTabList();
    },
    currentChange(e) {
      this.page = e;
      this.getTabList();
    },
    onePage() {
      this.page = 1;
      this.getTabList();
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
      this.getTabList();
    },
    async searchClick() {
      try {
        const res = await this.$http.post(
          `${this.baserUrl}/search`,
          qs.stringify({
            firstTime: getTime(this.dateTime.date1),
            lastTime: getTime(this.dateTime.date2),
            status: this.value,
            search: this.QueryContent,
            currentPage: this.page,
            currentPageTotal: this.length,
          })
        );
        this.tableData = res.data.data;
        this.tableFinshNum = res.data.count;
        // this.dateTime.date1 = "";
        // this.dateTime.date2 = "";
        // this.value = "";
        // this.QueryContent = "";
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    breadcrumb(newval) {
      if (newval == "满减活动") {
        this.breadcrumbs = 1;
      } else if (newval == "满折活动") {
        this.breadcrumbs = 2;
      } else if (newval == "满赠活动") {
        this.breadcrumbs = 3;
      } else if (newval == "满包邮活动") {
        this.breadcrumbs = 4;
      } else if (newval == "二维码活动") {
        this.breadcrumbs = 5;
      } else if (newval == "消费送活动") {
        this.breadcrumbs = 6;
      } else if (newval == "新手礼包活动") {
        this.breadcrumbs = 7;
      } else if (newval == "弹窗活动") {
        this.breadcrumbs = 8;
      }
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.tableBtn {
  padding: 5px 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}

.table {
  padding: 20px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.tableStatus {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 3px;
  font-weight: bold;
}
</style>
<style lang="less">
.el-select {
  margin: 0 5px !important;
  width: 8% !important;
}
</style>
