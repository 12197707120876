const urlList = [
  { title: "满减活动", url: "/full_reduction_activity", type: "满减" },
  { title: "满折活动", url: "/full_folding_activity", type: "满折" },
  { title: "满赠活动", url: "/full_gift_activity", type: "满赠" },
  { title: "满包邮活动", url: "/full_free_shipping_activity", type: "满包邮" },
  { title: "二维码活动", url: "/qr_code_activity", type: "二维码" },
  {
    title: "消费送活动",
    url: "/consumption_delivery_activity",
    type: "消费送",
  },
  {
    title: "新手礼包活动",
    url: "/novice_gift_pack_activity",
    type: "新手礼包",
  },
  { title: "弹窗活动", url: "/pop_up_activity", type: "弹窗" },
];
export { urlList };
